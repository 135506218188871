/**************************************************
 * Nombre:       Clientes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {TEXTOACENTO, TEXTOSUAVE} from "../../Constantes";
import BotonesAccion from "./Componentes/BotonesAccion";
import l_alkosto from '../../Recursos/l_alkosto.webp'
import l_exito from '../../Recursos/l_exito.webp'
import l_attmos from '../../Recursos/l_attmos.webp'
import l_corbeta from '../../Recursos/l_corbeta.webp'
import l_sonoco from '../../Recursos/l_sonoco.webp'
import l_grupo from '../../Recursos/l_grupo.webp'
import l_integral from '../../Recursos/integral.png'
import l_luker from '../../Recursos/luker.png'
import l_yupi from '../../Recursos/yupi.png'
import l_cyrgo from '../../Recursos/cyrgo.png'
import l_madecentro from '../../Recursos/l_madecentro.png'
import {theme} from "../../Tema";


const Clientes = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? 3 : 6}}
            >

                <Grid

                    item lg={12} sm={12} xs={12}>
                    <Typography sx={{
                        color: TEXTOACENTO,
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: 600
                    }}>Clientes</Typography>
                </Grid>

                <Grid item lg={5} sm={12} xs={12} sx={{marginTop: 5, px: 3}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={100}
                            data-aos-duration="1500"

                            item lg={12} sm={12} xs={12}>
                            <Typography sx={{fontSize: 32, fontFamily: "Montserrat", fontWeight: 800}}>Ellos confian en
                                nosotros</Typography>
                        </Grid>


                        <Grid

                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={150}
                            data-aos-duration="1500"
                            item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{fontSize: 18, fontWeight: 400}}>
                                Para International Logistics MD, nuestros clientes son lo principal en nuestra
                                operación, identificamos sus necesidades y exponemos el manejo de nuestras operaciones,
                                ejecución y trazabilidad con el fin de que pueda ser transparentes, y realizar una
                                entrega de manera rápida y confiable.
                            </Typography>
                        </Grid>

                        {!sCell &&
                            <>
                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                                    <BotonesAccion vertical={false}/>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={250}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                    <Typography sx={{fontSize: 14, fontWeight: 400, color: TEXTOSUAVE}}>
                                        Al contactar con nosotros realizaremos una presentación presencial o virtual
                                        como
                                        sea de
                                        su preferencia, para que conozcan nuestros servicios y nosotros conocer tus
                                        necesidades
                                        y así presentar una propuesta comercial que brinde soluciones a su compañía.
                                    </Typography>
                                </Grid>
                            </>
                        }


                    </Grid>
                </Grid>

                <Grid item container lg={7} sm={12} xs={12}
                      sx={{justifyContent: "center", p: sCell ? 3 : 6, marginTop: sCell ? 4 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        {!masSM ?
                            <>
                                {logosCell.map((item, index) => {
                                    return (
                                        <Grid
                                            component={"div"}
                                            data-aos="fade-up"
                                            data-aos-delay={250 * index}
                                            data-aos-duration="1000"
                                            item lg={4} sm={6} xs={6} key={index}>
                                            <img src={item} width={"100%"} height={"auto"} alt={"Clientes de MD"}
                                                 title={"Clientes de MD"}
                                            />
                                        </Grid>
                                    )
                                })}
                            </> :

                            <>
                                {logos.map((item, index) => {
                                    return (
                                        <Grid
                                            component={"div"}
                                            data-aos="fade-up"
                                            data-aos-delay={250 * index}
                                            data-aos-duration="1000"
                                            item lg={4} sm={6} xs={6} key={index}>
                                            <img src={item} width={"100%"} height={"auto"} alt={"Clientes de MD"}
                                                 title={"Clientes de MD"}
                                            />
                                        </Grid>
                                    )
                                })}
                            </>

                        }


                        {sCell &&
                            <>
                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                    data-aos-duration="1500"
                                    item lg={5} sm={12} xs={12} sx={{marginTop: 10}}>
                                    <BotonesAccion vertical={true}/>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={250}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 2, }}>
                                    <Typography sx={{fontSize: 16, fontWeight: 400, color: TEXTOSUAVE}}>
                                        Al contactar con nosotros realizaremos una presentación presencial o virtual
                                        como
                                        sea de
                                        su preferencia, para que conozcan nuestros servicios y nosotros conocer tus
                                        necesidades
                                        y así presentar una propuesta comercial que brinde soluciones a su compañía.
                                    </Typography>
                                </Grid>
                            </>
                        }

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Clientes;

const logos = [
    l_alkosto,
    l_exito,
    l_corbeta,
    l_attmos,
    l_sonoco,
    l_grupo,
    l_luker,
    l_integral,
    l_cyrgo,
    l_yupi,
    l_madecentro

]

const logosCell = [
    l_alkosto,
    l_exito,
    l_attmos,
    l_corbeta,
    l_sonoco,
    l_grupo,
    l_integral,
    l_luker,
    l_cyrgo,
    l_yupi,
    l_madecentro

]