/**************************************************
 * Nombre:       Seccion_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import logo from '../../Recursos/logo.svg'
import {ArrowLeft} from "iconsax-react";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {irUrl} from "../../Utilidades/irUrl";

const Seccion_Detalle = () => {
    const parms = useParams()
    const [adicional, setAdicional] = useState({})

    const htmlFrom = (htmlString) => {
        const cleanHtmlString = DOMPurify.sanitize(htmlString,
            {USE_PROFILES: {html: true}});
        const html = parse(cleanHtmlString);
        return html;
    }

    useEffect(() => {
        if (parms && parms.id) {
            obtenerDoc('adicionales', parms.id).then((dox) => {
                if (dox.res) {
                    setAdicional(dox.data)
                }
            })
        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                    <Button
                        onClick={() => window.history.back()}
                        startIcon={<ArrowLeft/>} variant={'text'} sx={{color: '#000', px: 0}}>Atras</Button>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end', marginTop: 2}}>
                    <img src={logo} width={180} height={'auto'}/>
                </Grid>

                <Grid item container lg={8} sm={12} xs={11} sx={{justifyContent: 'center'}}>
                    <Typography sx={{textAlign: 'justify'}}>
                        {adicional && htmlFrom(adicional?.textoEstilo)}
                    </Typography>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', m: 4}}>
                    {adicional.link &&
                        <Button
                            onClick={() => irUrl(adicional?.link)}
                        >Ver todo el reporte</Button>
                    }
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Seccion_Detalle