/**************************************************
 * Nombre:       Tarjeta_Anexos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {ArrowRight} from "iconsax-react";
import {useNavigate} from "react-router-dom";


const Tarjeta_Anexos = ({item}) => {
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: '#fff', p: 3, borderRadius: 2}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 24, fontWeight: 600, lineHeight: 1.1, minHeight: 50}}>
                    {item.nombre}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400,
                    textOverflow: "ellipsis",
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 4,
                }}>
                    {item.descripcion}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: 2}}>
                <Button
                    onClick={() => navigate('/Detalle/' + item.id)}
                    endIcon={<ArrowRight/>} variant={'text'} color={'primary'}
                    sx={{color: theme.palette.primary.main, px: 1, py: 0.2}}>Ver Mas</Button>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Anexos    