import React, {createContext} from 'react';
import './App.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import Dashboard from "./Dashboard/Dashboard";

export const usuarioContext = createContext();


function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})


    const valoresProvider = {
        usuario
    }

    const {Provider} = usuarioContext;


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {usuario ?
                <Provider value={valoresProvider}>
                    <Dashboard/>
                </Provider> :
                <Pagina/>}

        </Grid>
    );
}

export default App;
